import { Decision } from './CustomTypes';
import {
    productAmaroEDolce,
    productBlackColumbus,
    productBlackMassai,
    productBlackNo1,
    productConfianza,
    productCremaGiulio,
    productECaliente,
    productEConfianza,
    productEFuego,
    productEGiulio,
    productEJavaBlu,
    productENaturaleza,
    productElBar,
    productEVero,
    productEVulcano,
    productMonteDecaff,
    productMonteFino,
    productOrangUtan,
    productPeru,
    productScala,
    productValioso,
    productViena,
} from './products';

export const decisionTree: Decision = {
    slug: 'ZUBEREITUNGSART',
    title: 'WIE BEREITEN SIE IHREN KAFFEE ZU?',
    choices: [
        {
            name: 'VOLLAUTOMAT',
            image: '../media/icons/vollautomat.svg',
            decision: {
                slug: 'WAHL KAFFEE ODER ESPRESSO',
                title: 'TRINKEN SIE EHER CAFFÈ CREMA UND MILCHKAFFEE ODER ESPRESSO UND ESPRESSO-BASIERTE GETRÄNKE?',
                choices: [
                    {
                        name: 'CAFFÈ CREMA UND MILCHKAFFEE',
                        image: '../media/icons/crema-milchkaffee.svg',
                        decision: {
                            slug: 'WAHL CAFFÈ CREMA ODER MILCHKAFFEE',
                            title: 'TRINKEN SIE EHER CAFFÈ CREMA ODER MILCHKAFFEE?',
                            choices: [
                                {
                                    name: 'CAFFÈ CREMA',
                                    image: '../media/icons/caffe-crema.svg',
                                    decision: {
                                        slug: 'GESCHMACKSRICHTUNG',
                                        title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                                        choices: [
                                            {
                                                name: 'LEBENDIG-AROMATISCHER KAFFEE',
                                                image: '../media/icons/lebendig-aromatisch.svg',

                                                products: [productBlackNo1, productPeru, productOrangUtan],
                                            },
                                            {
                                                name: 'KRÄFTIGER KAFFEE',
                                                image: '../media/icons/kraeftig.svg',

                                                products: [
                                                    productConfianza,
                                                    productENaturaleza,
                                                    productBlackMassai,
                                                    productViena,
                                                ],
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'MILCHKAFFEE',
                                    image: '../media/icons/milchkaffe.svg',
                                    decision: {
                                        slug: 'GESCHMACKSRICHTUNG',
                                        title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                                        choices: [
                                            {
                                                name: 'LEBENDIG-AROMATISCHER KAFFEE',
                                                image: '../media/icons/lebendig-aromatisch.svg',
                                                products: [productValioso, productElBar],
                                            },
                                            {
                                                name: 'KRÄFTIGER KAFFEE',
                                                image: '../media/icons/kraeftig.svg',

                                                products: [
                                                    productBlackMassai,
                                                    productViena,
                                                    productScala,
                                                    productAmaroEDolce,
                                                ],
                                            },
                                        ],
                                    },
                                },
                            ],
                            additionalContent: {
                                header: 'Möchten Sie beides?',
                                content: 'Dann folgen Sie diesem Button.',
                                isDecaff: false,
                            },
                        },
                    },
                    {
                        name: 'ESPRESSO UND ESPRESSO-BASIERTE GETRÄNKE',
                        image: '../media/icons/espressos.svg',
                        isEspresso: true,
                        decision: {
                            slug: 'GESCHMACKSRICHTUNG',
                            title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                            choices: [
                                {
                                    name: 'LEBENDIG-AROMATISCHER KAFFEE',
                                    image: '../media/icons/lebendig-aromatisch.svg',

                                    products: [productEConfianza, productEJavaBlu, productEFuego],
                                },
                                {
                                    name: 'KRÄFTIGER KAFFEE',
                                    image: '../media/icons/kraeftig.svg',

                                    products: [
                                        productENaturaleza,
                                        productEVulcano,
                                        productECaliente,
                                        productEVero,
                                        productEGiulio,
                                    ],
                                },
                            ],
                        },
                    },
                ],
                additionalContent: {
                    header: 'Möchten Sie beides?',
                    content: 'Dann empfehlen wir eine Espresso-Bohne. Folgen Sie diesem Button.',
                    isDecaff: false,
                },
            },
        },
        {
            name: 'FILTERKAFFEE',
            image: '../media/icons/filterkaffee.svg',
            decision: {
                slug: 'GESCHMACKSRICHTUNG',
                title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                choices: [
                    {
                        name: 'LEBENDIG-AROMATISCHER KAFFEE',
                        image: '../media/icons/lebendig-aromatisch.svg',

                        products: [
                            productBlackNo1,
                            productBlackColumbus,
                            productMonteFino,
                            productOrangUtan,
                            productPeru,
                        ],
                    },
                    {
                        name: 'KRÄFTIGER KAFFEE',
                        image: '../media/icons/kraeftig.svg',

                        products: [productValioso, productViena, productScala],
                    },
                ],
            },
        },
        {
            name: 'FRENCH PRESS',
            image: '../media/icons/french-press.svg',
            decision: {
                slug: 'GESCHMACKSRICHTUNG',
                title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                choices: [
                    {
                        name: 'LEBENDIG-AROMATISCHER KAFFEE',
                        image: '../media/icons/lebendig-aromatisch.svg',

                        products: [productValioso, productOrangUtan, productPeru],
                    },
                    {
                        name: 'KRÄFTIGER KAFFEE',
                        image: '../media/icons/kraeftig.svg',

                        products: [
                            productConfianza,
                            productBlackMassai,
                            productViena,
                            productAmaroEDolce,
                            productScala,
                        ],
                    },
                ],
            },
        },
        {
            name: 'SIEBTRÄGER',
            image: '../media/icons/siebtraeger.svg',
            decision: {
                slug: 'GESCHMACKSRICHTUNG',
                title: 'MÖCHTEN SIE LIEBER EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                choices: [
                    {
                        name: 'LEBENDIG-AROMATISCHER KAFFEE',
                        image: '../media/icons/lebendig-aromatisch.svg',

                        products: [productEConfianza, productCremaGiulio, productEJavaBlu, productEFuego],
                    },
                    {
                        name: 'KRÄFTIGER KAFFEE',
                        image: '../media/icons/kraeftig.svg',

                        products: [productENaturaleza, productEGiulio, productEVulcano, productECaliente, productEVero],
                    },
                ],
            },
        },
        {
            name: 'MOKKA KANNE',
            image: '../media/icons/mokka-kanne.svg',

            products: [productEVulcano, productECaliente],
        },
        {
            name: 'ARCA  KANNE',
            image: '../media/icons/arca-kanne.svg',
            decision: {
                slug: 'GESCHMACKSRICHTUNG',
                title: 'MÖCHTEN SIE EINEN LEBENDIG-AROMATISCHEN ODER KRÄFTIGEN KAFFEE?',
                choices: [
                    {
                        name: 'LEBENDIG-AROMATISCHER KAFFEE',
                        image: '../media/icons/lebendig-aromatisch.svg',

                        products: [productOrangUtan, productPeru],
                    },
                    {
                        name: 'KRÄFTIGER KAFFEE',
                        image: '../media/icons/kraeftig.svg',

                        products: [productValioso, productBlackMassai],
                    },
                ],
            },
        },
        {
            name: 'MONTE DECAFF',
            image: '',

            products: [productMonteDecaff],
        },
    ],
    additionalContent: {
        header: 'entkoffeinierter Kaffee?',
        content: 'Möchten Sie entkoffeinierten Kaffee, dann empfehlen wir die Sorte "Monte Decaff".',
        isDecaff: true,
    },
};
